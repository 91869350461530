const clearBtn = document.querySelector('.clearBtn');

if (clearBtn) {
	const selects = document.querySelectorAll('select');

	clearBtn.addEventListener('click', () => {
		selects.forEach( (select) => {
			select.value = '';
		})
	})
}