const mq = window.matchMedia('(min-width: 801px)');

function lazyLoadImages() {
    let imgDefer = document.getElementsByTagName('img');
    for (let i = 0; i < imgDefer.length; i++) {
        if (imgDefer[i].getAttribute('data-src')) {
            imgDefer[i].setAttribute('src', imgDefer[i].getAttribute('data-src'));
        }
    }
}

function lazyLoadBGImages() {
    let bgImgDefer = document.querySelectorAll('.bgLazy');
    for (let i = 0; i < bgImgDefer.length; i++) {
        let url;
        let deskSrc = bgImgDefer[i].getAttribute('data-src');
        let mobSrc = bgImgDefer[i].getAttribute('data-mob-src');

        if (mq.matches) {
            if (deskSrc) {
                url = deskSrc;
            }
        } else {
            if (mobSrc) {
                url = mobSrc;
            } else {
                url = deskSrc;
            }
        }

        bgImgDefer[i].style.backgroundImage = 'url('+ url +')';
    }
}

window.addEventListener('load', () => {
    lazyLoadImages();
    lazyLoadBGImages();
});