const modalCloseBtn = document.querySelector('.modalCloseBtn');
const body = document.querySelector('body');

if (modalCloseBtn) {

	modalCloseBtn.addEventListener('click', () => {
		let modal = modalCloseBtn.closest('.jsModal');

		modal.classList.remove('is-active');
		body.classList.remove('no-scroll');
	})
}