const stepTriggers = document.querySelectorAll('.stepTrigger');
const stepTriggerValues = document.querySelectorAll('.stepTriggerValue');
const selectedEnquiry = document.getElementById('selectedEnquiry');
const contactForm = document.getElementById('contactForm');
const scrollTo = document.querySelector('.scrollTo');
let hiddenItems;

if (stepTriggers) {

	if (contactForm) {
		hiddenItems = contactForm.querySelectorAll('.hidden');
	}

	stepTriggers.forEach( (item) => {
		item.addEventListener('change', () => {
			let values = [];

			hiddenItems.forEach( (hiddenItem) => {
				hiddenItem.classList.remove('hidden');
			})

			stepTriggers.forEach( (value) => {
				if (value.value) {
					values.push(value.value);
				}
			})

			selectedEnquiry.value = values.join('');
		})
	})

	window.addEventListener('load', () => {
		let values = [];

		stepTriggerValues.forEach( (item) => {
			if (item.value) {
				values.push(item.value);

				hiddenItems.forEach( (hiddenItem) => {
					hiddenItem.classList.remove('hidden');
				})
			}
		})

		if (values.length !== 0) {
			selectedEnquiry.value = values.join('');
		}

		if (scrollTo) {
			scrollTo.scrollIntoView({
				behavior: "smooth",
				block: "start",
				inline: "nearest"
			})
		}
	})
}