const PhotoSwipe = require('photoswipe');
const photoSwipeModal = document.querySelector('.photoSwipeModal');
const PhotoSwipeUI_Default = require('photoswipe/dist/photoswipe-ui-default');
const galleryItems = document.querySelectorAll('.galleryItem');
let items = [];

if (galleryItems) {

	window.addEventListener('load', () => {
		galleryItems.forEach( (item) => {
			let itemInfo = {
				src: item.href,
				w: item.dataset.width,
				h: item.dataset.height,
				title: item.dataset.caption
			};

			items.push(itemInfo);

			console.log(items);
		})
	})

	galleryItems.forEach( (item) => {
		item.addEventListener('click', (e) => {
			e.preventDefault();

			let posStartsZero = parseInt(item.dataset.pos, 10) - 1;

			const options = {
				// get index and convert to integer
				index: posStartsZero,
				bgOpacity: 1,
				showHideOpacity: true,
				closeOnScroll: false,
				addCaptionHTMLFn: function(itemInfo, captionEl, isFake) {
					let matcher = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/;

					function isUrl(string){
						return matcher.test(string);
					}

					if(!itemInfo.title) {
						captionEl.children[0].innerHTML = '';
						return false;
					}

					if (isUrl(itemInfo.title) === true) {
						captionEl.children[0].innerHTML = '<a href="'+ itemInfo.title +'" target="__blank">' + itemInfo.title + '</a>';
					} else {
						captionEl.children[0].innerHTML = itemInfo.title;
					}

					return true;
				}
			};

			// Initialise PhotoSwipe
			const gallery = new PhotoSwipe(photoSwipeModal, PhotoSwipeUI_Default, items, options);
			gallery.init();
		})
	})
}