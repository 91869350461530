const siteHeader = document.querySelector('.siteHeader');
const navTrigger = document.querySelector('.navTrigger');
const navLinkTrigger = document.querySelectorAll('.navLinkTrigger');
const navBackTrigger = document.querySelectorAll('.navBackTrigger');
const nav = document.querySelector('.nav');
let position;

if (navTrigger) {

	navTrigger.addEventListener('click', () => {
		navTrigger.classList.toggle('is-active');
		nav.classList.toggle('is-active');
		siteHeader.classList.toggle('is-active');
		document.body.classList.toggle('no-scroll');
	})
}

if (navLinkTrigger) {

	navLinkTrigger.forEach( (item) => {
		item.addEventListener('click', () => {
			let secondaryNav = item.nextElementSibling;
			item.classList.toggle('is-active');
			secondaryNav.classList.toggle('is-active');
		})
	})
}

if (navBackTrigger) {

	navBackTrigger.forEach( (item) => {
		item.addEventListener('click', () => {
			let secondaryNav = item.closest('.secondaryNav');
			secondaryNav.classList.remove('is-active');
		})
	})
}