const accordionItems = document.querySelectorAll('.accordionItem');

if (accordionItems) {
	accordionItems.forEach((item) => {
		let accordionTitle = item.querySelector('.accordionTitle');
		let accordionContent = item.querySelector('.accordionContent');

		accordionTitle.addEventListener('click', function() {
			item.classList.toggle('is-active');
			accordionTitle.classList.toggle('is-active');
			accordionContent.classList.toggle('is-active');
		})
	})
}