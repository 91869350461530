const sectionsNavLinks = document.querySelectorAll('button[data-anchor]')

if (sectionsNavLinks) {
	sectionsNavLinks.forEach((item) => {
		console.log(item);
		item.addEventListener('click', () => {
			let section = item.dataset.anchor;
			let targetedSection = document.getElementById(section);

			targetedSection.scrollIntoView({
				behavior: "smooth",
				block: "start",
				inline: "nearest"
			})
		})
	})
}
