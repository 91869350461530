const searchTriggers = document.querySelectorAll('.searchTrigger');
const searchModal = document.querySelector('.searchModal');
const body = document.querySelector('body');

if (searchTriggers) {

	searchTriggers.forEach( (item) => {
		item.addEventListener('click', () => {
			searchModal.classList.add('is-active');
			body.classList.add('no-scroll');
		})
	})
}