const dropdownItems = document.querySelectorAll('.dropdownItem');

//fix for ie11 forEach
if (window.NodeList && !NodeList.prototype.forEach) {
	NodeList.prototype.forEach = Array.prototype.forEach;
}
//fix for ie11 forEach

if (dropdownItems) {
	dropdownItems.forEach((dropdownItem) => {
		let dropdownTrigger = dropdownItem.querySelector('.dropdownTrigger');
		let dropdownContent = dropdownItem.querySelector('.dropdownContent');

		dropdownItem.addEventListener('click', () => {
			dropdownTrigger.classList.toggle('is-active');
			dropdownContent.classList.toggle('is-active');
		})
	});
}