const hotspotTriggers = document.querySelectorAll('.hotspotTrigger');

if (hotspotTriggers) {

	window.addEventListener('load', () => {
		hotspotTriggers.forEach( (item, index) => {
			setTimeout( () => {
				item.classList.add('is-active');
				item.parentElement.classList.add('is-active');
			}, index * 1000)
		})
	})

	hotspotTriggers.forEach( (item) => {
		item.addEventListener('click', () => {
			item.classList.toggle('is-active');
			item.parentElement.classList.toggle('is-active');
		})

		item.addEventListener('mouseover', () => {
			item.classList.toggle('is-active')
			item.parentElement.classList.toggle('is-active');
		})
	})
}