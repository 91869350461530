var COURSES = null;
var CoursesDateArray = [];
var AllDateArray = [];
var dayTotals = {};
var pageSlug = $('body').attr('data-page-slug');

$(document).ready(function () {
    if (pageSlug && pageSlug === 'booking') {
        fetchCourses();
    }
    accordion();
    tabs();
});


function fetchCourses() {
    $.ajax({
        url: '/courses-import/import-json',
        method: 'GET',
        success: function (courses) {
            COURSES = courses;
        }
    });
}

function accordion() {
    $('.accordion-header').click(function (e) {

        $('.accordion-header').removeClass('on');
        $(this).addClass('on');
        $(this).closest('.accordion-content').show();
        $(this).toggleClass('active');

        var content = $(this).next();
        content.slideToggle('normal');
        // content.slideToggle('normal', function () {
        //     if (content.find('.js-calendar').length) {
        //         var initCalendarInterval = setInterval(function () {
        //             initCalendar(content.find('.js-calendar'));
        //
        //             if (COURSES) {
        //                 clearInterval(initCalendarInterval);
        //             }
        //         }, 100);
        //     }
        // });

        $('.arrow').click(function () {
            $(this).closest('.accordion-content').slideToggle();
        });

        $('.accordion-header').mouseover(function () {
            $(this).addClass('over');
        }).mouseout(function () {
            $(this).removeClass('over');
        });

        if ($('.book-page').length && window.location.hash && $(window.location.hash).length) {
            $(window.location.hash).trigger('click');
        }
    });
}

function tabs() {
    $('.js-tab-content .js-content').hide();
    $('.js-tab-content').each(function () {
        $(this).find('.js-content:last').show();
    });

    $('.js-tab-nav li').click(function (e) {
        e.preventDefault();

        $(this).closest('.js-tab-menu').find('.js-tab-nav li a').removeClass("active");
        $(this).find('a').addClass("active");
        $(this).closest('.js-tab-menu').find('.js-tab-content .js-content').hide();

        var indexer = $(this).index();

        var contentTab = $(this).closest('.js-tab-menu').find('.js-tab-content .js-content:eq(' + indexer + ')');
        contentTab.fadeIn('normal', function () {
            loadCalendar($(this));
        });

        setTimeout(function () {

        }, 10);
        return false;
    });
}

function loadCalendar(contentTab) {
    if (contentTab.find('.js-calendar').length) {
        var initCalendarInterval = setInterval(function () {
            initCalendar(contentTab.find('.js-calendar'));

            if (COURSES) {
                clearInterval(initCalendarInterval);
            }
        }, 10);
    }
}

function initCalendar(dom) {
    if (!COURSES) return false;

    var courseId = dom.data('course-id');
    var calendar = dom.fullCalendar({
        // timeZone: 'UTC',
        events: COURSES[courseId],
        eventOverlap: true,
        isMultipleDay: true,

        header: {
            left: 'prev title next',
            center: '',
            right: ''
        },

        eventRender: function (event, element) {
            var startDay = moment(event.start).format('YYYY-MM-DD');
            var endDay = moment(event.end).format('YYYY-MM-DD');

            var daysList = getDays(new Date(startDay), new Date(endDay));
            var output = daysList.map(
                function (v) {
                    return v.toISOString().slice(0, 10);
                }
            );

            for (var i = 0; i < output.length; i++) {

                var dayDom = dom.find("td[data-date='" + output[i] + "']");
                dayDom.removeClass('fc-state-highlight');

                if (event.status === 'Available') {
                    dayDom.addClass('available');
                } else {
                    dayDom.addClass('booked');
                }
            }
        },

        dayRender: function (date, cell) {
            var fullDay = moment(date._d).format('YYYY-MM-DD');
            var dayDom = dom.find("td.fc-day-number[data-date='" + fullDay + "']");
            var weekday = moment(date._d).format('ddd');
            var day = moment(date._d).format('DD');
            dayDom.html('<div class="weekday">' + weekday + '</div><div class="date">' + day + '</div>');
        },

        dayClick: function (date, allDay, jsEvent, view) {
            dom.find('.fc-day').removeClass('selected');
            showEventInfo(date.format(), $(this));
        }
    });

    function getDays(startDate, endDate) {
        for (var arr = [], dt = new Date(startDate); dt <= endDate; dt.setDate(dt.getDate() + 1)) {
            arr.push(new Date(dt));
        }
        return arr;
    }

    $(document).keydown(function (e) {
        var newDate;
        var dayDom;

        dom.find('.fc-day').removeClass('selected');
        if (e.keyCode === 37) {
            newDate = moment(dom.data('selected')).add(-1, 'day').format('YYYY-MM-DD');
        } else if (e.keyCode === 39) {
            newDate = moment(dom.data('selected')).add(1, 'day').format('YYYY-MM-DD');
        } else if (e.keyCode === 38) {
            newDate = moment(dom.data('selected')).add(-7, 'day').format('YYYY-MM-DD');
        } else if (e.keyCode === 40) {
            newDate = moment(dom.data('selected')).add(7, 'day').format('YYYY-MM-DD');
        }
        dom.fullCalendar('gotoDate', newDate);
        dayDom = dom.find("td[data-date='" + newDate + "']");
        showEventInfo(newDate, dayDom);
    });

    function showEventInfo(date, dayDom) {
        dayDom.addClass('selected');
        dom.data('selected', moment(date).format('YYYY-MM-DD'));
        dom.closest('.js-content').find('.js-event-2').hide();

        var events = COURSES[courseId];
        var selectedEvent = [];
        for (var i = 0; i < events.length; i++) {
            var daysList = getDays(new Date(events[i].start), new Date(events[i].end));
            var outputDays = daysList.map(
                function (v) {
                    return v.toISOString().slice(0, 10)
                }
            );

            for (var j = 0; j < outputDays.length; j++) {
                if (moment(date).format('YYYY-MM-DD') === moment(outputDays[j]).format('YYYY-MM-DD')) {
                    selectedEvent.push(events[i]);
                    dom.on('click', function () {
                        selectDates(selectedEvent.start, selectedEvent.end, selectedEvent.duration);
                    });
                    break;
                }
            }
        }
        dom.closest('.js-content').find('.date-header').html(moment(date).format('MMM YY'));
        dom.closest('.js-content').find('.date-content').html(moment(date).format('DD'));

        var hasEventInfo = dom.closest('.js-content').find('.date-info-wrapper.has-event');
        var noEventInfo = dom.closest('.js-content').find('.date-info-wrapper.no-event');
        if (selectedEvent.length) {
            if (selectedEvent.length > 1) {
                dom.closest('.js-content').find('.js-event-2').show();
            }
            for (let i = 0; i < selectedEvent.length; i++) {
                hasEventInfo.show();

                hasEventInfo.find('.duration-' + (i + 1)).html(parseFloat(selectedEvent[i].duration) + ' Day' + ((selectedEvent[i].duration === '1.00' || selectedEvent[i].duration === '1') ? '' : 's'));
                hasEventInfo.find('.time-duration-' + (i + 1)).html(selectedEvent[i].startTime + ' to ' + selectedEvent[i].endTime);
                hasEventInfo.find('.price-' + (i + 1)).html(selectedEvent[i].cost);
                hasEventInfo.find('.location-' + (i + 1)).html(selectedEvent[i].location);
                hasEventInfo.find('.status-' + (i + 1)).html(selectedEvent[i].status);

                var bookNowButton = hasEventInfo.find('.book-button-' + (i + 1));
                if (selectedEvent[i].bookNowURL != null && selectedEvent[i].status === 'Available') {
                    bookNowButton.show();
                    bookNowButton.attr('href', selectedEvent[i].bookNowURL);
                } else {
                    bookNowButton.hide();
                }

                if (selectedEvent[i].status === 'Available') {
                    hasEventInfo.find('.status-' + (i + 1)).addClass('available');
                } else if (selectedEvent[i].status === 'Cancelled' ||
                    selectedEvent[i].status === 'FullyBooked' ||
                    selectedEvent[i].status === 'Fully Booked') {

                    hasEventInfo.find('.status-' + (i + 1)).removeClass('available');
                    hasEventInfo.find('.status-' + (i + 1)).addClass('booked');
                }

                noEventInfo.hide();

            }

        } else {
            hasEventInfo.hide();
            noEventInfo.show();
        }
    }

    function selectDates(start, end, duration) {
        // if(!start) {
        //     dom.find('.fc-day').removeClass('selected');
        // }

        var arr = [];

        for (var i = 0; i < duration; i++) {
            var day = new Date(start);
            var days = day.setDate(day.getDate() + i);
            arr.push(new Date(days));

        }

        var output = arr.map(
            function (v) {
                return v.toISOString().slice(0, 10);
            }
        );

        for (var j = 0; j < output.length; j++) {
            var dayDom = dom.find("td[data-date='" + output[j] + "']");

            if (output.indexOf(moment(dom.data('selected')).format('YYYY-MM-DD')) > -1) {
                dayDom.addClass('selected');
            } else {
                if ((duration % 1) === 0.5 && end === output[j]) {
                    dayDom.addClass('selected');
                    dayDom.addClass('half');
                }
                dayDom.removeClass('selected');
            }
        }
    }

    $('button.fc-next-button').on('click', function (e) {
        var newDate = moment(dom.data('selected')).add(1, 'month').format('YYYY-MM-DD');
        var dayDom = dom.find("td[data-date='" + newDate + "']");

        showEventInfo(newDate, dayDom);
    });

    $('button.fc-prev-button').on('click', function (e) {
        var newDate = moment(dom.data('selected')).add(-1, 'month').format('YYYY-MM-DD');
        var dayDom = dom.find("td[data-date='" + newDate + "']");

        showEventInfo(newDate, dayDom);
    });

    // select today on load of calendar
    setTimeout(function () {
        var cell = dom.find('td.fc-day.fc-today');
        showEventInfo(cell.data('date'), cell);
    }, 100)
}