import Cookies from 'js-cookie'
const popup = document.querySelector('.popup');
const popupTriggers = document.querySelectorAll('.popupTrigger');
const cookieBanner = document.querySelector('.cookieBanner');
const cookieBannerTrigger = document.querySelector('.cookieBannerTrigger');

if (popup) {

	popupTriggers.forEach( (item) => {
		item.addEventListener('click', () => {
			Cookies.set('popup', 'clicked', { expires: 365 })
			popup.classList.add('hidden');
		})
	})

	window.addEventListener('load', () => {

		if (Cookies.get('popup') !== 'clicked') {
			popup.classList.remove('hidden');
		}
	})
}

if (cookieBanner) {

	cookieBannerTrigger.addEventListener('click', () => {
		Cookies.set('cookies', 'accepted', { expires: 365 })
		cookieBanner.classList.add('hidden');
	})

	window.addEventListener('load', () => {

		if (Cookies.get('cookies') !== 'accepted') {
			cookieBanner.classList.remove('hidden');
		}
	})
}